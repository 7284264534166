/* Общие стили календаря */
.rbc-calendar {
    font-family: 'Arial', sans-serif; /* Пример шрифта, измените по вашему выбору */
    color: #333; /* Тёмный цвет текста для лучшей читаемости */
    border: 1px solid #ddd; /* Границы для элементов календаря */
    background-color: #fff; /* Фоновый цвет элементов */
}

/* Стилизация кнопок навигации */
.rbc-btn-group button {
    color: #333; /* Цвет текста кнопок */
    background-color: transparent; /* Прозрачный фон кнопок */
    border: 1px solid #ccc; /* Граница кнопок */
    padding: 5px 10px; /* Паддинг для кнопок */
    outline: none; /* Убрать стандартный outline при фокусе */
}

.rbc-btn-group button:hover {
    background-color: #f0f0f0; /* Цвет фона кнопок при наведении */
}

/* Стилизация событий в календаре */
.rbc-event {
    background-color: #007bff; /* Цвет фона событий */
    color: white; /* Цвет текста событий */
    border-radius: 5px; /* Скругление углов событий */
    padding: 2px 5px; /* Паддинг внутри событий */
    border: none; /* Убрать границу событий */
    min-width: 100%;
}

.rbc-event:hover {
    background-color: #0056b3; /* Темный цвет фона событий при наведении */
}

/* Стилизация заголовка месяца */
.rbc-toolbar-label {
    font-size: 16px; /* Размер шрифта для месяца и года */
    color: #333; /* Цвет шрифта для месяца и года */
}

/* Стилизация дней недели */
.rbc-header {
    background-color: #e9ecef; /* Светлый фон для заголовков дней */
    color: #333; /* Цвет текста дней */
    font-weight: bold; /* Жирное начертание для дней */
    padding: 5px 0; /* Паддинг для дней */
}

.react-contextmenu {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 10000; /* Убедитесь, что меню выше всех элементов */
}

.react-contextmenu-item {
    padding: 8px 12px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
}

.react-contextmenu-item:hover {
    background-color: #f7f7f7;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    z-index: 1100;
}

.ReactModal__Content {
    position: relative;
    margin: auto;
    border: 1px solid #ccc;
    background: #f0f0f0; /* Светлый серый фон, чтобы выделить модальное окно на фоне страницы */
    overflow: auto;
    WebkitOverflowScrolling: 'touch';
    border-radius: 10px; /* Скругление углов */
    outline: none;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Тень для добавления глубины */
    max-width: 500px; /* Ограничение максимальной ширины модального окна */
    max-height: 80vh; /* Ограничение максимальной высоты окна до 80% от высоты экрана */
    width: auto;
    height: auto;
    color: #333; /* Темно-серый цвет текста для контрастности */
    z-index: 1100;
    scrollbar-width: thin; /* Для Firefox */
}

.ReactModal__Content::-webkit-scrollbar {
    width: 12px; /* Ширина scrollbar для Chrome, Safari и Opera */
}

.ReactModal__Content::-webkit-scrollbar-thumb {
    background: #888; /* Цвет ползунка */
    border-radius: 10px;
}

.ReactModal__Content::-webkit-scrollbar-thumb:hover {
    background: #555; /* Цвет ползунка при наведении */
}

.ReactModal__Content::-webkit-scrollbar-track {
    background: #f0f0f0; /* Цвет фона scrollbar */
}

/* Стили для текстовых полей внутри модального окна */
.doctor-dashboard-modal input,
.doctor-dashboard-modal textarea,
.doctor-dashboard-modal select {
    width: 100%;
    padding: 8px;
    margin: 5px 0 20px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff; /* Белый фон текстовых полей для контрастности */
    color: #333; /* Черный цвет текста в полях */
}

.doctor-dashboard-modal input:focus, .doctor-dashboard-modal select:focus {
    border: 0.1rem solid #997af0;
    outline: none;
}

.doctor-dashboard-modal input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 15px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.doctor-dashboard-modal input[type="submit"]:hover {
    background-color: #45a049;
}

.doctor-dashboard-modal label {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center items horizontally */
}

.doctor-dashboard-modal input[type="checkbox"] {
    margin-right: 5px;
}


.doctor-dashboard-modal input[type="checkbox"] {
    margin-right: 5px;
}

.document-section {
    background-color: white;    /* Установка белого фона */
    border: 1px solid #ddd;     /* Светлая рамка для менее выраженной объёмности */
    padding: 10px;              /* Меньше внутреннего пространства */
    margin-top: 5px;            /* Отступ сверху для разделения с заголовком */
    border-radius: 4px;         /* Немного скруглённые углы */
    box-shadow: 0 1px 3px rgba(0,0,0,0.1); /* Более лёгкая тень */
}

.download-section-title {
    font-size: 16px;            /* Размер шрифта для заголовка */
    margin-top: 20px;           /* Отступ от предыдущих элементов */
    margin-bottom: 5px;         /* Маленький отступ перед контейнером документов */
}

.download-link {
    display: flex;
    align-items: center;
    gap: 8px;                   /* Меньшее расстояние между иконкой и текстом */
    color: #0056b3;             /* Синий цвет ссылок для лучшей видимости */
    text-decoration: none;
    margin-bottom: 5px;         /* Отступ между ссылками */
}

.download-link:hover {
    background-color: #f8f8f8; /* Светло-серый фон при наведении */
    border-radius: 4px;        /* Скругление углов фона при наведении */
}


.rbc-events-container{
    width: 100%;
}

.rbc-event-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 6px; /* Добавьте отступы для лучшего визуального восприятия */
}

@media (max-width: 768px) {
    .rbc-event-content {
        font-size: 12px; /* Уменьшаем шрифт для устройств с маленьким экраном */
    }
}

@media (min-width: 769px) {
    .rbc-event-content {
        font-size: 14px; /* Больший шрифт для устройств с большим экраном */
    }
}

.rbc-month-column {
    display: inline-table !important;
    flex: 0 0 0 !important;
    min-height: 50px !important;
    min-width: auto !important;
}

.rbc-week-row {
    display: inline-table !important;
    flex: 0 0 0 !important;
    min-height: 50px !important;
}


.questions-container {
    width: 100%; /* Ensure the questions container takes the full width */
}

.questions-title {
    text-align: left; /* Align title to the left */
    margin-bottom: 10px; /* Space between the title and questions */
}

.question-answer {
    display: flex;
    justify-content: space-between; /* Space between question and answer */
    align-items: center; /* Align items vertically */
    padding: 10px 0; /* Padding for better spacing */
    border-bottom: 1px solid #ddd; /* Border to separate each question */
}

.question {
    flex: 1; /* Ensure question takes available space */
}

.answer {
    flex: 1; /* Ensure answer takes available space */
    text-align: right; /* Align answer to the right */
    font-weight: bold;
}
