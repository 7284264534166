/* PriceList.css */
.price-list-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 30px;
    width: 100%;
    font-family: "Poppins", sans-serif;
}

.disclaimer-container {
    align-self: flex-start; /* Добавьте это свойство */
    width: 100%; /* Убедитесь, что контейнер занимает всю ширину */
    margin-bottom: 30px;
}

.price-list-container h2 {
    color: #232323;
    font-size: 27px;
    margin-bottom: 30px;
}

.section {
    width: 90%;
    margin-bottom: 30px;
}

.section h3 {
    color: #344c5d;
    margin-bottom: 15px;
}

.price-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.price-table th, .price-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    color: #232323;
}

.price-table th {
    background-color: #f2f2f2;
}

.price-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.price-table tr:hover {
    background-color: #f1f1f1;
}

.disclaimer {
    color: #344c5d;
    margin-top: 20px;
    text-align: left;
}
