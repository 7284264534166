#si_02,
#si_03 {
  display: none;
}

#home {
  padding: 0 0 10px 0;
}
.slider_image img {
  width: 100%;
  margin-top: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.short_info {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 421px;
}
.circle_dot_class {
  width: 10px;
  height: 10px;
  background: gray;
  border-radius: 50%;
}

.slider_image,
.slider_container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.slider-images {
  width: 90%;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 0px 16px;
}
.front_page_info {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}
.front_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.slider_controls {
  display: flex;
  width: 100px;
  align-items: center;
  align-content: center;
  gap: 7px;
  justify-content: center;
}
.front_page_info button {
  height: 45px;
}
.clinic_info {
  width: 95%;
  gap: 12px;
  display: flex;
  align-items: center;
  margin: 10px 15px 10px 15px;
  justify-content: center;
}
.clinic_container {
  display: flex;
}
.basic_info {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  align-content: flex-start;
  flex-direction: column;
  color: #fff;
  padding: 15px 20px;
  width: 80%;
  height: 240px;
  border-radius: 15px;
  box-shadow: 0px 0px 8px #3a3939;
}
#info_01 {
  background-color: #2c3d99;
}
#info_02 {
  background-color: #2d68c0;
}
#info_03 {
  background-color: #19407b;
}
.transperent_btn {
  display: flex;
  text-align: center;
  align-items: center;
  height: 45px;
  color: antiquewhite;
  width: 164px;
  outline: 1px solid white;
  justify-content: center;
  align-content: center;
  line-height: 24px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 0 #fff;
  transition: ease-in-out 0.3s;
  font-family: Poppins, sans-serif;
  cursor: pointer;
}
.transperent_btn:hover {
  color: black;
  outline: 1px solid #fff;
  box-shadow: inset 164px 0 0 0 #fff;
}
.transperent_btn:active {
  transform: scale(0.9);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
#tr_btn_01 {
  width: 214px;
  line-height: 24px;
}
#tr_btn_01:hover {
  box-shadow: inset 214px 0 0 0 #fff;
}
.slick-slide img {
  margin: auto;
  width: 50%;
  height: 50%;
}
.imgslider {
  width: 400px;
}
@media screen and (min-width: 1024px) {
  .slider-images {
    width: 90%;
    height: 500px;
  }
  .front_page_info p {
    font-size: 21px;
    text-align: left;
    width: 529px;
  }
  .clinic_container {
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .slider_image img {
    width: 594px !important;
    height: 409px;
    padding: 0;
    margin: 0;
  }
  .front_container {
    width: 555px;
  }
  .slider_controls {
    position: relative;
    top: 119px;
    left: 341px;
  }
  .basic_info {
    width: 400px;
  }
}
@media screen and (max-width: 1024px) {
  .slider_controls {
    display: none;
  }
  .front_page_info p {
    width: 85%;
    text-align: left;
    margin-left: -49px;
  }
}
@media screen and (max-width: 924px) {
  .clinic_info {
    flex-direction: column;
  }
  .front_page_info {
    justify-content: space-evenly;
    height: 280px;
  }
  .front_page_info p {
    width: 90%;
    text-align: left;
    margin-left: -12px;
  }
  .slider-images {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .slider-images {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 580px) {
  .slider-images {
    flex-direction: column-reverse;
    margin-bottom: -50px !important;
    height: 694px;
  }
  .slider_image img {
    background-size: cover;
  }
  .short_info {
    top: 342px;
  }
  .slider_controls {
    top: 279px;
  }
  .front_page_info {
    margin-top: -18px;
    height: 252px;
  }
  .front_container,
  .slider_image {
    width: 90%;
  }
  .slider_controls {
    display: none;
  }
  .front_page_info p {
    font-size: 18px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 480px) {
  .slider_image img {
    width: 100%;
    background-position: center;
    background-size: cover;
    display: list-item;
  }
  .clinic_info {
    width: 100%;
    flex-direction: column;
    margin: 30px 0px 40px 0px;
  }

  .basic_info {
    height: 260px;
    width: 90%;
    box-shadow: 0px 0px 2px #3a3939;
  }
  .transperent_btn {
    color: #000;
    outline: 1px solid #000;
  }
  #info_01,
  #info_02 {
    background-color: #ffffff;
    color: #000;
  }
  #info_03 {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .slider-images {
    margin: 0;
    margin-bottom: -50px !important;
    height: 694px;
    width: 100%;
    padding: 60px 0 20px 0 !important;
    justify-content: flex-end !important;
  }
  .front_container {
    width: 90%;
  }
  .front_page_info p {
    width: 85%;
  }
  .front_page_info h2 {
    width: 90%;
  }
}
