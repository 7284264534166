button {
    padding: 10px 20px;
    margin: 5px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}

.event:hover {
    background-color: #ddd; /* Светлее на hover */
}

@media (hover: none) {
    .event:hover {
        background-color: initial; /* Нет эффекта на тач-устройствах */
    }
}
